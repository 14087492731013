import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";
import PaginationData from "../../general/PaginationData";
import EmployeeGrantImg from "@/assets/images/grants.svg";
import EmployeeGrantsFilter from "./EmployeeGrantsFilter";
import EmployeesGrantsDataList from "./EmployeesGrantsDataList";

export default class EmployeeGrant {
  defaultImg = EmployeeGrantImg;
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.employeeGrantToken = "";
    this.grantToken = "";
    this.grantNameCurrent = "";
    this.grantTypeToken = "";
    this.grantTypeNameCurrent = "";
    this.mathematicalTypeToken = "";
    this.mathematicalTypeNameCurrent = "";
    this.grantReasonCurrent = "";
    this.grantReasonAr = "";
    this.grantReasonEn = "";
    this.grantReasonUnd = "";
    this.employeeGrantNotes = "";
    this.grantValue = "";
    this.hourRateFactorActivationStatus = false;
    this.hourRateFactor = "";
    this.employeeToken = "";
    this.employeeNameCurrent = "";
    this.employeeMakeActionToken = "";
    this.employeeMakeActionNameCurrent = "";
    this.employeeCancelToken = "";
    this.employeeCancelNameCurrent = "";
    this.actionDateTime = "";
    this.actionDate = "";
    this.actionTime = "";
    this.cancelDateTime = "";
    this.cancelDate = "";
    this.cancelTime = "";
    this.cancelStatus = false;
    this.employeeGrantArchiveStatus = false;
    this.employeesGrantsDataList = [new EmployeesGrantsDataList()];
  }
  fillData(data) {
    this.employeeGrantToken = data.employeeGrantToken;
    this.grantToken = data.grantToken;
    this.grantNameCurrent = data.grantNameCurrent;
    this.grantTypeToken = data.grantTypeToken;
    this.grantTypeNameCurrent = data.grantTypeNameCurrent;
    this.mathematicalTypeToken = data.mathematicalTypeToken;
    this.mathematicalTypeNameCurrent = data.mathematicalTypeNameCurrent;
    this.grantReasonCurrent = data.grantReasonCurrent;
    this.grantReasonAr = data.grantReasonAr;
    this.grantReasonEn = data.grantReasonEn;
    this.grantReasonUnd = data.grantReasonUnd;
    this.employeeGrantNotes = data.employeeGrantNotes;
    this.grantValue = data.grantValue;
    this.hourRateFactorActivationStatus = data.hourRateFactorActivationStatus;
    this.hourRateFactor = data.hourRateFactor;
    this.employeeToken = data.employeeToken;
    this.employeeNameCurrent = data.employeeNameCurrent;
    this.employeeMakeActionToken = data.employeeMakeActionToken;
    this.employeeMakeActionNameCurrent = data.employeeMakeActionNameCurrent;
    this.employeeCancelToken = data.employeeCancelToken;
    this.employeeCancelNameCurrent = data.employeeCancelNameCurrent;
    this.actionDateTime = data.actionDateTime;
    this.actionDate = data.actionDate;
    this.actionTime = data.actionTime;
    this.cancelDateTime = data.cancelDateTime;
    this.cancelDate = data.cancelDate;
    this.cancelTime = data.cancelTime;
    this.cancelStatus = data.cancelStatus;
    this.employeeGrantArchiveStatus = data.employeeGrantArchiveStatus;
  }

  async getAllEmployeeGrants(
    language,
    userAuthorizeToken,
    pagination = new PaginationData(),
    filterData = new EmployeeGrantsFilter()
  ) {
    try {
      let response = await axios.get(
        `/EmployeesGrants/GetAllEmployeesGrants/?language=${language}&userAuthorizeToken=${userAuthorizeToken}&employeeGrantArchiveStatus=false&page=${pagination.selfPage}&paginationStatus=true&pageSize=${PAGE_SIZE}&filterStatus=true&grantToken=${filterData.grantToken}&employeeToken=${filterData.employeeToken}&textSearch=${filterData.textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfEmployeeGrants(
    language,
    userAuthorizeToken,
    filterData = new EmployeeGrantsFilter()
  ) {
    return await axios.get(
      `/EmployeesGrants/GetDialogOfEmployeesGrants?language=${language}&userAuthorizeToken=${userAuthorizeToken}&employeeGrantArchiveStatus=false&filterStatus=true&grantToken=${filterData.grantToken}&employeeToken=${filterData.employeeToken}&textSearch=${filterData.textSearch}`
    );
  }

  async getEmployeeGrantDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/EmployeesGrants/GetEmployeesGrantDetails??language=${language}&userAuthorizeToken=${userAuthorizeToken}&employeeGrantToken=${this.employeeGrantToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addEmployeeGrant(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      employeesGrantsDataList: this.employeesGrantsDataList,
    };
    console.log(data);

    try {
      return await axios.post(`/EmployeesGrants/AddEmployeesGrant`, data);
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async cancelEmployeeGrant(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      employeesGrantsDataList: this.employeesGrantsDataList,
    };

    try {
      return await axios.post(`/EmployeesGrants/CancelEmployeeGrant`, data);
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
