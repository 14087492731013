<template>
  <div class="">
    <form autocomplete="off">
      <div :class="currentTab ? 'custom-tab-card' : 'my-card'">
        <div
          class="my-card"
          v-for="(
            employeeGrant, index
          ) in employeeGrantData.employeesGrantsDataList"
          :key="index"
        >
          <span class="my-card-title">{{ index + 1 }}</span>

          <span class="remove-slice-container" v-if="index != 0">
            <button
              @click="removeSlice(index)"
              type="button"
              class="btn btn-remove-slice"
            >
              ×
            </button>
          </span>
          <div class="row">
            <CustomSelectBox
              :className="'col-md-6'"
              :id="`employeesGrantsDataList[${index}][grantToken]`"
              :value="employeeGrant.grantToken"
              :options="grantTokenOptions"
              v-on:changeValue="employeeGrant.grantToken = $event"
              :title="$t('employeeGrants.select')"
              :imgName="'grants.svg'"
            />
            <CustomSelectBox
              :className="'col-md-6'"
              :id="`employeesGrantsDataList[${index}][employeeToken]`"
              :value="employeeGrant.employeeToken"
              :options="employeeTokenOptions"
              v-on:changeValue="employeeGrant.employeeToken = $event"
              :title="$t('selectEmployee')"
              :imgName="'employees.svg'"
            />
            <CustomCheckbox
              :className="'col-md-3 col-lg-3'"
              :id="`employeesGrantsDataList[${index}][hourRateFactorActivationStatus]`"
              :value="employeeGrant.hourRateFactorActivationStatus"
              :title="$t('employeeGrants.hourRateFactorActivationStatus')"
              v-on:changeValue="
                employeeGrant.hourRateFactorActivationStatus = $event
              "
            />

            <CustomInputFloat
              v-if="employeeGrant.hourRateFactorActivationStatus"
              :className="'col-md-6 col-lg-6'"
              :id="`employeesGrantsDataList[${index}][hourRateFactor]`"
              :value="employeeGrant.hourRateFactor"
              v-on:changeValue="employeeGrant.hourRateFactor = $event"
              :title="$t('employeeGrants.hourRateFactor')"
              :imgName="'hours.svg'"
            />
            <CustomInputFloat
              v-else
              :className="'col-md-6 col-lg-6'"
              :id="`employeesGrantsDataList[${index}][grantValue]`"
              :value="employeeGrant.grantValue"
              v-on:changeValue="employeeGrant.grantValue = $event"
              :title="$t('employeeGrants.value')"
              :imgName="'money.svg'"
            />
            <DatePicker
              :className="'col-md-3 col-lg-3'"
              :id="`employeesGrantsDataList[${index}][employeeGrantDate]`"
              :value="employeeGrant.employeeGrantDate"
              :title="$t('employeeGrants.date')"
              :language="language"
              v-on:changeValue="employeeGrant.employeeGrantDate = $event"
            />
            <TextArea
              :className="'col-md-6 col-lg-6'"
              :id="`employeesGrantsDataList[${index}][grantReasonAr]`"
              :value="employeeGrant.grantReasonAr"
              v-on:changeValue="employeeGrant.grantReasonAr = $event"
              :title="$t('employeeGrants.reasonAr')"
              :imgName="'reason.svg'"
            />
            <TextArea
              :className="'col-md-6 col-lg-6'"
              :id="`employeesGrantsDataList[${index}][grantReasonEn]`"
              :value="employeeGrant.grantReasonEn"
              v-on:changeValue="employeeGrant.grantReasonEn = $event"
              :title="$t('employeeGrants.reasonEn')"
              :imgName="'reason.svg'"
            />
            <TextArea
              :className="'col-md-6 col-lg-6'"
              :id="`employeesGrantsDataList[${index}][grantReasonUnd]`"
              :value="employeeGrant.grantReasonUnd"
              v-on:changeValue="employeeGrant.grantReasonUnd = $event"
              :title="$t('employeeGrants.reasonUnd')"
              :imgName="'reason.svg'"
            />

            <TextArea
              :className="'col-md-6'"
              :id="`employeesGrantsDataList[${index}][employeeGrantNotes]`"
              :value="employeeGrantData.employeeGrantNotes"
              v-on:changeValue="employeeGrantData.employeeGrantNotes = $event"
              :title="$t('notes')"
              :imgName="'notes.svg'"
            />
          </div>
        </div>

        <div class="add-slice-container">
          <button
            @click="addSlice"
            type="button"
            class="btn btn-add-slice"
            :title="$t('addSlice')"
          >
            +
          </button>
        </div>
      </div>

      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="addEmployeeGrant"
        >
          {{ submitName }}
        </button>
        <button @click.prevent="routeTo()" class="btn btn-cancel">
          {{ $t("cancel") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { STATUS } from "@/utils/constants";
import axios from "axios";
import createToastMixin from "@/utils/create-toast-mixin";
import TextArea from "@/components/general/TextArea.vue";
// import CustomInput from "@/components/general/CustomInput.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import CustomCheckbox from "@/components/general/CustomCheckbox.vue";
import CustomInputFloat from "@/components/general/CustomInputFloat.vue";
import DatePicker from "@/components/general/DatePicker.vue";
import Grant from "@/models/settings/settingsSalary/grants/Grant";
import EmployeesGrantsDataList from "@/models/employees/employeeGrants/EmployeesGrantsDataList";

export default {
  name: "EmployeeGrantForm",
  mixins: [createToastMixin],
  components: {
    TextArea,
    // CustomInput,
    CustomSelectBox,
    CustomCheckbox,
    CustomInputFloat,
    DatePicker,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      grant: new Grant(),
      employeesGrantsDataList: new EmployeesGrantsDataList(),
      grantTokenOptions: [],
      employeeTokenOptions: [],
    };
  },
  props: ["employeeGrantData", "submitName", "currentTab"],
  methods: {
    routeTo() {
      if (this.currentTab) {
        this.$router
          .push({
            name: "EmployeeGrantsTab",
          })
          .catch(() => {});
      } else {
        this.$router
          .push({
            name: "EmployeeGrants",
          })
          .catch(() => {});
      }
    },
    addSlice() {
      if (this.currentTab) {
        this.employeesGrantsDataList.employeeToken =
          this.employeeGrantData.employeeToken;
      }
      this.employeeGrantData.employeesGrantsDataList.push(
        this.employeesGrantsDataList
      );
    },
    removeSlice(index) {
      this.employeeGrantData.employeesGrantsDataList.splice(index, 1);
    },
    async addEmployeeGrant() {
      if (
        this.employeeGrantData.employeesGrantsDataList
          .hourRateFactorActivationStatus
      ) {
        this.employeeGrantData.employeesGrantsDataList.grantValue = 0;
      }

      this.$emit("addEmployeeGrant");
    },
    async getDialogOfGrants() {
      this.isLoading = true;
      this.grantTokenOptions = [];

      try {
        const response = await this.grant.getDialogOfGrants(
          this.language,
          this.userAuthorizeToken
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.grantTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfEmployees() {
      this.isLoading = true;
      this.employeeTokenOptions = [];

      try {
        const response = await axios.get(
          `/Employees/GetDialogOfEmployees?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}`
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.employeeTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getDialogOfGrants();
    this.getDialogOfEmployees();
  },
};
</script>

<style scoped lang="scss"></style>
