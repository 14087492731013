<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <EmployeeGrantForm
      v-if="!isLoading"
      :employeeGrantData="employeeGrant"
      :currentTab="currentTab"
      :submitName="$t('add')"
      v-on:addEmployeeGrant="addEmployeeGrant()"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import EmployeeGrantForm from "@/components/employees/employeeGrants/EmployeeGrantForm.vue";
import createToastMixin from "@/utils/create-toast-mixin";
import currentTabMixin from "@/utils/currentTab-mixin";
import EmployeeGrant from "@/models/employees/employeeGrants/EmployeeGrant";

export default {
  name: "EmployeeGrantAdd",
  mixins: [createToastMixin, currentTabMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    EmployeeGrantForm,
  },
  computed: {
    ...mapGetters(["employeeToken"]),
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      employeeGrant: new EmployeeGrant(),
    };
  },
  methods: {
    async addEmployeeGrant() {
      this.isLoading = true;
      try {
        let response = await this.employeeGrant.addEmployeeGrant(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.employeeGrant.setInitialValue();
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  async created() {
    if (this.currentTab && this.employeeToken) {
      this.employeeGrant.employeesGrantsDataList[0].employeeToken =
        this.employeeToken;
      this.employeeGrant.employeeToken = this.employeeToken;
    }
  },
};
</script>
