export default class EmployeesGrantsDataList {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.grantToken = "";
    this.employeeGrantToken = "";
    this.grantReasonAr = "";
    this.grantReasonEn = "";
    this.grantReasonUnd = "";
    this.employeeGrantDate = "";
    this.employeeGrantDate = "";
    this.employeeGrantNotes = "";
    this.hourRateFactorActivationStatus = false;
    this.hourRateFactor = "";
    this.grantValue = "";
    this.employeeToken = "";
  }
  fillData(data) {
    this.grantToken = data.grantToken;
    this.employeeGrantToken = data.employeeGrantToken;
    this.grantReasonAr = data.grantReasonAr;
    this.grantReasonEn = data.grantReasonEn;
    this.grantReasonUnd = data.grantReasonUnd;
    this.employeeGrantDate = data.employeeGrantDate;
    this.employeeGrantDate = data.employeeGrantDate;
    this.employeeGrantNotes = data.employeeGrantNotes;
    this.hourRateFactorActivationStatus = data.hourRateFactorActivationStatus;
    this.hourRateFactor = data.hourRateFactor;
    this.grantValue = data.grantValue;
    this.employeeToken = data.employeeToken;
  }
}
